// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.impressum-container {
  min-height: 90vh;
  margin: 200px 0 3rem 0;
}

.impressum-title {
  text-align: left;
  margin: auto;
  color: var(--text-color-dark);
  margin-top: 0;
}

.impressum-subtitle {
  text-align: left;
  color: var(--text-color-dark);
  padding-top: 4%;
  padding-bottom: 2%;
}

.impressum-grey-block {
  padding-left: 5%;
  background-color: #f4f4f4;
  color: var(--text-color-dark);
  min-height: 450px;
  position: relative;
  width: 100%;
  height: 100%;
}
.impressum-white-block {
  padding-left: 5%;
  background-color: white;
  color: var(--text-color-dark);
  min-height: 450px;
  position: relative;
  width: 100%;
  margin-bottom: 4rem;
}

.impressum-article {
  text-align: left;
  color: var(--text-color-dark);
  max-width: 60%;
  letter-spacing: 3px;
  line-height: 1.8;
}

@media only screen and (max-width: 677px) {
  .impressum-article {
    max-width: 90%;
    letter-spacing: 1px;
    line-height: 1;
    padding-left: 5%;
  }
}
@media only screen and (max-width: 1024px) {
  .impressum-article {
    padding-left: 20%;
    max-width: 75%;
    letter-spacing: 2px;
    line-height: 1.3;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/impressum.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,6BAA6B;EAC7B,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,6BAA6B;EAC7B,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,6BAA6B;EAC7B,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,6BAA6B;EAC7B,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,6BAA6B;EAC7B,cAAc;EACd,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;IACE,cAAc;IACd,mBAAmB;IACnB,cAAc;IACd,gBAAgB;EAClB;AACF;AACA;EACE;IACE,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,gBAAgB;EAClB;AACF","sourcesContent":[".impressum-container {\n  min-height: 90vh;\n  margin: 200px 0 3rem 0;\n}\n\n.impressum-title {\n  text-align: left;\n  margin: auto;\n  color: var(--text-color-dark);\n  margin-top: 0;\n}\n\n.impressum-subtitle {\n  text-align: left;\n  color: var(--text-color-dark);\n  padding-top: 4%;\n  padding-bottom: 2%;\n}\n\n.impressum-grey-block {\n  padding-left: 5%;\n  background-color: #f4f4f4;\n  color: var(--text-color-dark);\n  min-height: 450px;\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n.impressum-white-block {\n  padding-left: 5%;\n  background-color: white;\n  color: var(--text-color-dark);\n  min-height: 450px;\n  position: relative;\n  width: 100%;\n  margin-bottom: 4rem;\n}\n\n.impressum-article {\n  text-align: left;\n  color: var(--text-color-dark);\n  max-width: 60%;\n  letter-spacing: 3px;\n  line-height: 1.8;\n}\n\n@media only screen and (max-width: 677px) {\n  .impressum-article {\n    max-width: 90%;\n    letter-spacing: 1px;\n    line-height: 1;\n    padding-left: 5%;\n  }\n}\n@media only screen and (max-width: 1024px) {\n  .impressum-article {\n    padding-left: 20%;\n    max-width: 75%;\n    letter-spacing: 2px;\n    line-height: 1.3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
