// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-content {
  z-index: 1;
  text-align: left;
  line-height: 1.3;
  margin: 0 auto;
}
.address-content h4 {
  padding: 4px;
}

.address-highlight {
  background-color: var(--secondaryColor);
  color: var(--text-color-dark) !important;
  display: inline;
}

.address-highlight a {
  color: var(--text-color-light) !important;
  text-decoration: none;
  cursor: pointer;
}

.address-highlight a:hover {
  color: var(--secondaryLightColor) !important;
  transition: color 1s;
}
`, "",{"version":3,"sources":["webpack://./src/css/contactAddress.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,YAAY;AACd;;AAEA;EACE,uCAAuC;EACvC,wCAAwC;EACxC,eAAe;AACjB;;AAEA;EACE,yCAAyC;EACzC,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,4CAA4C;EAC5C,oBAAoB;AACtB","sourcesContent":[".address-content {\n  z-index: 1;\n  text-align: left;\n  line-height: 1.3;\n  margin: 0 auto;\n}\n.address-content h4 {\n  padding: 4px;\n}\n\n.address-highlight {\n  background-color: var(--secondaryColor);\n  color: var(--text-color-dark) !important;\n  display: inline;\n}\n\n.address-highlight a {\n  color: var(--text-color-light) !important;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n.address-highlight a:hover {\n  color: var(--secondaryLightColor) !important;\n  transition: color 1s;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
