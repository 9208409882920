// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.column-2a {
  width: 45%;
  height: fit-content;
  margin: 0 0.5rem;
}
.column-2a h5 {
  margin-bottom: 1rem;
}
.skills-container {
  position: absolute;
  bottom: 8% !important;
  right: 5% !important;
  background-color: var(--light-grey);
}

.cross:before {
  content: 'X';
  position: absolute;
  top: 3%;
  right: 3%;
}
.cross:hover {
  cursor: pointer;
  scale: 1.2;
  transition: scale 2 ease-out;
}
.skills-table {
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid var(--darkGrey);
  border-radius: var(--borderRadius);
  padding: 1rem;
}

@media only screen and (max-width: 677px) {
  .skills-table {
    flex-direction: column-reverse;

    padding: 0.2rem;
  }
  .column-2a {
    width: 100%;
    margin: 1rem auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/skillsBlock.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,oBAAoB;EACpB,mCAAmC;AACrC;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,OAAO;EACP,SAAS;AACX;AACA;EACE,eAAe;EACf,UAAU;EACV,4BAA4B;AAC9B;AACA;EACE,aAAa;EACb,2BAA2B;EAC3B,iCAAiC;EACjC,kCAAkC;EAClC,aAAa;AACf;;AAEA;EACE;IACE,8BAA8B;;IAE9B,eAAe;EACjB;EACA;IACE,WAAW;IACX,iBAAiB;EACnB;AACF","sourcesContent":[".column-2a {\n  width: 45%;\n  height: fit-content;\n  margin: 0 0.5rem;\n}\n.column-2a h5 {\n  margin-bottom: 1rem;\n}\n.skills-container {\n  position: absolute;\n  bottom: 8% !important;\n  right: 5% !important;\n  background-color: var(--light-grey);\n}\n\n.cross:before {\n  content: 'X';\n  position: absolute;\n  top: 3%;\n  right: 3%;\n}\n.cross:hover {\n  cursor: pointer;\n  scale: 1.2;\n  transition: scale 2 ease-out;\n}\n.skills-table {\n  display: flex;\n  flex-direction: row-reverse;\n  border: 1px solid var(--darkGrey);\n  border-radius: var(--borderRadius);\n  padding: 1rem;\n}\n\n@media only screen and (max-width: 677px) {\n  .skills-table {\n    flex-direction: column-reverse;\n\n    padding: 0.2rem;\n  }\n  .column-2a {\n    width: 100%;\n    margin: 1rem auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
