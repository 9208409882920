// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.modal-main {
  position: fixed;
  background: white;
  min-width: 90vw;
  min-height: 90vh;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid gray;
  border-radius: 11px;
  padding: 2rem;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.close {
  cursor: pointer;
  background-color: gray;
  color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 11px;
  /* position: absolute;
  top: 23px;
  right: 33px; */
}

.close:hover {
  background-color: white;
  color: gray;
}

@media only screen and (max-width: 678px) {
  .modal-main {
    width: auto;
    padding: 0.1rem;
  }
}

.iframe-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}
.i-frame {
  margin-top: 20px;
}

.i-object {
  width: 100%;
  height: 700px;
}

.alt-info {
  padding: 2rem;
}
.alt-info a {
  text-decoration: underline;
  text-underline-offset: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/css/modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,gBAAgB;;EAEhB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,YAAY;EACZ,2BAA2B;EAC3B,kBAAkB;EAClB,aAAa;EACb;;gBAEc;AAChB;;AAEA;EACE,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE;IACE,WAAW;IACX,eAAe;EACjB;AACF;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,aAAa;AACf;AACA;EACE,0BAA0B;EAC1B,0BAA0B;AAC5B","sourcesContent":[".modal {\n  position: fixed;\n  top: 100px;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.6);\n  z-index: 99;\n}\n\n.modal-main {\n  position: fixed;\n  background: white;\n  min-width: 90vw;\n  min-height: 90vh;\n\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  border: 1px solid gray;\n  border-radius: 11px;\n  padding: 2rem;\n}\n\n.display-block {\n  display: block;\n}\n\n.display-none {\n  display: none;\n}\n\n.close {\n  cursor: pointer;\n  background-color: gray;\n  color: white;\n  border: 1px solid lightgrey;\n  border-radius: 4px;\n  padding: 11px;\n  /* position: absolute;\n  top: 23px;\n  right: 33px; */\n}\n\n.close:hover {\n  background-color: white;\n  color: gray;\n}\n\n@media only screen and (max-width: 678px) {\n  .modal-main {\n    width: auto;\n    padding: 0.1rem;\n  }\n}\n\n.iframe-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 1rem;\n}\n.i-frame {\n  margin-top: 20px;\n}\n\n.i-object {\n  width: 100%;\n  height: 700px;\n}\n\n.alt-info {\n  padding: 2rem;\n}\n.alt-info a {\n  text-decoration: underline;\n  text-underline-offset: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
