// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-container {
  min-height: 90vh;
  margin: 200px 0 3rem 0;
}

.privacy-title {
  text-align: left;
  margin: auto;
  color: var(--text-color-dark);
  margin-top: 0;
  padding-top: 2rem;
  overflow-wrap: break-word;
}

.privacy-subtitle {
  text-align: left;
  color: var(--text-color-dark);
  padding-top: 4%;
  padding-bottom: 2%;
}

.privacy-grey-block {
  padding-left: 5%;
  padding-right: 5%;

  background-color: #f4f4f4;
  color: var(--text-color-dark);
  min-height: 450px;
  position: relative;
  width: 100%;
  height: 100%;
}
.privacy-white-block {
  padding-left: 5%;
  padding-right: 5%;
  background-color: white;
  color: var(--text-color-dark);
  min-height: 450px;
  position: relative;
  width: 100%;
}

.privacy-article {
  text-align: left;
  color: var(--text-color-dark);
}

@media only screen and (max-width: 677px) {
  .privacy-container {
    margin-bottom: 8rem;
  }
}
@media only screen and (max-width: 1024px) {
  .privacy-container {
    margin-bottom: 11rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/privacyConsent.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,6BAA6B;EAC7B,aAAa;EACb,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,6BAA6B;EAC7B,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;;EAEjB,yBAAyB;EACzB,6BAA6B;EAC7B,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,6BAA6B;EAC7B,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;AACA;EACE;IACE,oBAAoB;EACtB;AACF","sourcesContent":[".privacy-container {\n  min-height: 90vh;\n  margin: 200px 0 3rem 0;\n}\n\n.privacy-title {\n  text-align: left;\n  margin: auto;\n  color: var(--text-color-dark);\n  margin-top: 0;\n  padding-top: 2rem;\n  overflow-wrap: break-word;\n}\n\n.privacy-subtitle {\n  text-align: left;\n  color: var(--text-color-dark);\n  padding-top: 4%;\n  padding-bottom: 2%;\n}\n\n.privacy-grey-block {\n  padding-left: 5%;\n  padding-right: 5%;\n\n  background-color: #f4f4f4;\n  color: var(--text-color-dark);\n  min-height: 450px;\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n.privacy-white-block {\n  padding-left: 5%;\n  padding-right: 5%;\n  background-color: white;\n  color: var(--text-color-dark);\n  min-height: 450px;\n  position: relative;\n  width: 100%;\n}\n\n.privacy-article {\n  text-align: left;\n  color: var(--text-color-dark);\n}\n\n@media only screen and (max-width: 677px) {\n  .privacy-container {\n    margin-bottom: 8rem;\n  }\n}\n@media only screen and (max-width: 1024px) {\n  .privacy-container {\n    margin-bottom: 11rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
